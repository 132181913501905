
document.addEventListener("DOMContentLoaded", function () {
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#002a42",
                "text": "#ffffff"
            },
            "button": {
                "background": "#faf08f",
                "text": "#000000"
            }
        },
        "content": {
            "message": "Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.",
            "dismiss": "Zgadzam się",
            "link": "Polityka prywatności",
            "href": "https://muub.pl/polityka-prywatnosci/"
        }
    });
});